
import { mapState } from "vuex";
import VClamp from 'vue-clamp';
import baseComponent from "@/scripts/baseComponent";
import lazyLoadComponent from "@/scripts/lazyLoadComponent";
import SkeletonBox from "@/components/SkeletonBox";
import chatwootHistoryApi from "@/api/common/chatwootHistoryApi";
import usersApi from '@/api/common/users';
import moment from 'moment';
import { debounce } from 'vue-debounce';
export default {
    extends: baseComponent,
    components: {
        VClamp,
        chatwootHistoryView: lazyLoadComponent({
            componentFactory: () => import("@/views/users/ChatwootHistoryView"),
            loading: SkeletonBox,
        }),
    },
    data() {
        return {
            isLoading: true,
            isInited: false,
            searchList: () => { },
            querySearch: "",
            dateRangeFilter: [],
            currentPage: 1,
            userItems: [],
            userSelected: null,
            debounceFnc: debounce(() => {

            }, 300),
        };
    },
    watch: {
        dateRangeFilter: {
            handler: function (val, oldVal) {
                this.userSelected = null;
                this.$set(this, 'userItems', []);
                this.$store.dispatch('CANCEL_PENDING_REQUESTS');
                this.getChatContacts("REFRESH");
            },
            deep: true
        },
        querySearch: {
            handler: function (val, oldVal) {
                this.debounceFnc();
            },
            deep: true
        }
    },
    mounted() {
        this.getChatContacts("REFRESH");
        this.debounceFnc = debounce(() => {
            this.userSelected = null;
            this.$set(this, 'userItems', []);
            this.$store.dispatch('CANCEL_PENDING_REQUESTS');
            this.getChatContacts("REFRESH");
        }, 300);
    },
    beforeDestroy() {
        $("#userChatScroller").off("scroll");
    },
    methods: {
        performAction(options) {
            switch (options) {
                case "Today":
                    this.dateRangeFilter = [moment().format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')];
                    break;
                case "ThisWeek":
                    this.dateRangeFilter = [moment().startOf('weeks').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')];
                    break;
                case "Yesterday":
                    this.dateRangeFilter = [moment().subtract(-1, 'days').format('YYYY-MM-DD'), moment().subtract(-1, 'days').format('YYYY-MM-DD')];
                    break;
                case "LastWeek":
                    this.dateRangeFilter = [moment().subtract(1, 'weeks').startOf('week').format('YYYY-MM-DD'), moment().subtract(1, 'weeks').endOf('week').format('YYYY-MM-DD')];
                    break;
                case "ThisMonth":
                    this.dateRangeFilter = [moment().startOf('month').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')];
                    break;
                case "LastMonth":
                    this.dateRangeFilter = [moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD'), moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD')];
                    break;
                default:
                    break;
            }
        },
        changeUserConversation(userItem) {
            this.userSelected = userItem;
        },
        onWaypoint({ going, direction, userItem }) {
            if(userItem.name.includes('-') || userItem.userDetailInfo || userItem.isLoading) return;
            userItem.isLoading = true;
            usersApi.getUserDetailByUsername(userItem.name)
                .then(response => {
                    if (response.data && response.data.result === 0) {
                        this.$set(userItem, 'userDetailInfo', response.data.data);
                    }
                })
                .catch(error => {
                    console.error(error);
                })
        },
        getChatContacts(targetAction = "LOAD_MORE") {
            this.isLoading = true;
            let filterDateArray = [];
            if (this.dateRangeFilter && this.dateRangeFilter !== null && this.dateRangeFilter.length > 0) {
                filterDateArray = [this.dateRangeFilter[0], this.dateRangeFilter[1]];
            }
            else filterDateArray = ['', '']; 
            chatwootHistoryApi.getChatContacts(this.querySearch, filterDateArray[0], filterDateArray[1], this.currentPage, 50)
                .then((response) => {
                    if (response.data && response.data.result === 0) {
                        //console.log("getConversation", response.data.data);
                        if (response.data.data.length > 0) {
                            switch (targetAction) {
                                case "LOAD_MORE":
                                    this.$set(this, 'userItems', (response.data.data).map(xItem => {
                                        let jsonAttributes = {};
                                        try{
                                            jsonAttributes = JSON.parse(xItem.customAttributes);
                                        }catch{
                                            console.log('No Json attributes');
                                        }
                                        return {
                                            ...xItem,
                                            customAttributesJson : jsonAttributes,
                                        }
                                    }).concat(this.userItems));
                                    break;
                                case "REFRESH":
                                    this.$set(this, 'userItems', (response.data.data).map(xItem => {
                                        let jsonAttributes = {};
                                        try{
                                            jsonAttributes = JSON.parse(xItem.customAttributes);
                                        }catch{
                                            console.log('No Json attributes');
                                        }
                                        return {
                                            ...xItem,
                                            customAttributesJson : jsonAttributes,
                                        }
                                    }));
                                    if (this.userItems.length > 0) {
                                        this.userSelected = this.userItems[0];
                                        //this.getChatwootHistoryByConversation(this.userSelected.id, "REFRESH");
                                    }
                                    this.$nextTick(() => {
                                        if (!this.isInited) {
                                            this.isInited = true;
                                            $("#userChatScroller").on("scroll", () => {
                                                let scrollerHeight = $("#userChatScroller").prop("scrollHeight");
                                                if ($("#userChatScroller").scrollTop() === scrollerHeight) {
                                                    this.loadOlder();
                                                }
                                            });
                                        }
                                    });
                                    break;
                            }
                        }
                        else {
                            if (this.currentPage > 1) this.currentPage = this.currentPage - 1;
                            this.userSelected = null;
                            this.$set(this, 'userItems', []);
                        }
                        this.isLoading = false;
                    } else {
                        if (response.data && response.data.message !== null && response.data.message !== "") {
                            console.error(response.data.message || this.$lang.common.error);
                        }
                        this.isLoading = false;
                    }
                })
                .catch((error) => {
                    console.error(error);
                    this.isLoading = false;
                });
        },
        loadOlder() {
            this.$store.dispatch('CANCEL_PENDING_REQUESTS');
            this.currentPage = this.currentPage + 1;
            this.getChatContacts("LOAD_MORE");
        },
    }
}